import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginData, AuthData, RegisterData, UpdatePasswordData, CmsUser } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  signIn(data: LoginData) {
    return this.http.post<CmsUser>(`${this.BASE_URL}api/auth/login`, data);
  }

  otpSignIn(otp: string, isPersistent: boolean = false) {
    return this.http.post<CmsUser>(`${this.BASE_URL}api/auth/verify-otp`, { otp, isPersistent });
  }

  checkAuth() {
    return this.http.get<CmsUser>(`${this.BASE_URL}api/auth/user`, { withCredentials: true });
  }

  SignOut() {
    return this.http.post<any>(`${this.BASE_URL}api/auth/logout`, null);
  }

  register(regData: RegisterData) {
    return this.http.post<CmsUser>(`${this.BASE_URL}api/auth/register`, regData);
  }

  forgotPassword(email: string) {
    return this.http.post<{ succeeded: boolean }>(`${this.BASE_URL}api/auth/forgot-password?email=${email}`, null);
  }

  resetPassword(token: string, uid: string, passwords: any) {
    return this.http.post<any>(`${this.BASE_URL}api/auth/reset-password?uid=${uid}&token=${encodeURIComponent(token)}`, passwords);
  }

  switchTenant(tenantId: string) {
    return this.http.post<CmsUser>(`${this.BASE_URL}api/auth/switch-tenant?tenantId=${tenantId}`, null);
  }
}
