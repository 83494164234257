import { createAction, props } from '@ngrx/store';
import { AuthData, LoginData, CmsUser, RegisterData, UpdatePasswordData } from '../../models';

export const CheckAuth = createAction('[Auth] Check Auth');
export const CheckAuthSuccess = createAction('[Auth] Check Auth Success', props<{ user: CmsUser }>());
export const CheckAuthFail = createAction('[Auth] Check Auth Fail', props<{ error: any }>());

export const SignIn = createAction('[Auth] Sign In', props<{ loginData: LoginData }>());
export const SignInSuccess = createAction('[Auth] Sign In Success', props<{ user: CmsUser }>());
export const SignInFail = createAction('[Auth] Sign In Fail', props<{ error: any }>());

export const SignIn2FA = createAction('[Auth] Sign In 2FA', props<{ otp: string; isPersistent?: boolean }>());
export const SignIn2FASuccess = createAction('[Auth] Sign In 2FA Success', props<{ user: CmsUser }>());
export const SignIn2FAFail = createAction('[Auth] Sign In 2FA Fail', props<{ error: any }>());

export const SignOut = createAction('[Auth] Sign Out');
export const SignOutSuccess = createAction('[Auth] Sign Out Success', props<{ result: { success: boolean } }>());
export const SignOutFail = createAction('[Auth] Sign Out Fail', props<{ error: any }>());

export const SwitchTenant = createAction('[Auth] Switch Tenant', props<{ tenantId: string }>());
export const SwitchTenantSuccess = createAction('[Auth] Switch Tenant Success', props<{ user: CmsUser }>());
export const SwitchTenantFail = createAction('[Auth] Switch Tenant Fail', props<{ error: any }>());

export const Register = createAction('[Auth] Register', props<{ registerData: RegisterData }>());
export const RegisterSuccess = createAction('[Auth] Register Success', props<{ result: CmsUser }>());
export const RegisterFail = createAction('[Auth] Register Fail', props<{ error: any }>());

export const UpdateAccount = createAction('[Auth] Update Account', props<{ accountData: any }>());
export const UpdateAccountSuccess = createAction('[Auth] Update Account Success', props<{ profile: CmsUser }>());
export const UpdateAccountFail = createAction('[Auth] Update Account Fail', props<{ error: any }>());

export const UpdatePassword = createAction('[Auth] Update Password', props<{ passwordData: UpdatePasswordData }>());
export const UpdatePasswordSuccess = createAction('[Auth] Update Password Success', props<{ result: any }>());
export const UpdatePasswordFail = createAction('[Auth] Update Password Fail', props<{ error: any }>());

export const RemoveErrors = createAction('[Auth] Remove errors');
